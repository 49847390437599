export default {



    data() {
        return {

            isYoutube : false,
            isVimeo : false,
            isDailymotion : false,
            isCoub : false,
            EMBED_TYPE : '',

            isValid: false,
            EMBED_URL: '',

            videos: [
                {
                    type : 'youtube',
                    reg:/^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w-]+\?v=|embed\/|v\/)?)([\w-]+)(\S+)?$/i,
                    url: 'https://www.youtube.com/embed/$5',
                    params: {
                        'picture-in-picture': 1,
                        accelerometer: 1,
                        gyroscope: 1

                    }


                },
                {
                    type : 'vimeo',
                    reg: /^.*vimeo.com\/(\d+)($|\/|\b)/i,
                    url: 'https://player.vimeo.com/video/$1',
                    params: {
                        title: 0,
                        byline: 0,
                        portrait: 0
                    },
                },

                {
                    type : 'dailymotion',
                    reg: /^.*(?:\/video|dai.ly)\/([A-Za-z0-9]+)([^#&?]*).*/i,
                    url: 'https://www.dailymotion.com/embed/video/$1',
                    params: {
                        autoplay: 0
                    }
                },

                {
                    type : 'coub',
                    reg: /^.*coub.com\/(?:embed|view)\/([A-Za-z0-9]+)([^#&?]*).*/i,
                    url: 'https://coub.com/embed/$1',
                    params: {
                        autoplay: 0
                    }
                },


                // {
                //     reg: /^.*twitch.tv\/videos\/(\d+)($|\/|\b)/i,
                //     url: 'https://player.twitch.tv/?autoplay=false&video=v$1',
                //     params: {
                //         autoplay: false
                //     },
                //     callback: function () {
                //         return 'https://player.twitch.tv/?video=v$1';
                //     }

                // }
            ],

        }
    },
    
    methods: {

        PARSE_EMBED_SRC(src) {
            if (src) {

                for (let i = 0; i < this.videos.length; i++) {
                    const embedType = this.videos[i];

                    if (embedType.reg.exec(src)) {

                        let params = Object.assign({}, embedType.params, this.params);
                        let query = Object.keys(params).map(key => key + '=' + params[key]).join('&');
                        let and = embedType.url.indexOf('?') >= 0 ? '&' : '?';

                        this.EMBED_URL = src.replace(embedType.reg, embedType.url) + and + query;
                        this.isValid = true;

                        this.EMBED_TYPE = embedType.type
                        this.isYoutube = embedType.type === 'youtube'
                        this.isVimeo = embedType.type === 'vimeo'
                        this.isDailymotion = embedType.type === 'dailymotion'
                        this.isCoub = embedType.type === 'coub'


                        return this.EMBED_URL
                    }

                }

                if ( ! this.isValid ) {
                    this.EMBED_URL = ''
                    return null
                }

            }

            this.isValid = false;
        },

    },

    mounted() {

        this.parse();

    }


}

